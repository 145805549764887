import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/database";

import firebaseConfig from "./firebase-config";

import Countdown from "./Countdown";

firebase.initializeApp(firebaseConfig);

const localStorage = window.localStorage;

const Login = () => {
  const [name, setName] = useState("");
  const [song, setSong] = useState("");
  const [withCompanion, setWithCompanion] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    const storageLogged = localStorage.getItem("logged");
    if (storageLogged) {
      setLoggedIn(true);
    }
  }, []);

  const changeName = (value) => {
    setName(value);
    document.querySelector("#elmo").style.height = `${value.length * 30}px`;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (name.trim() === "") {
      alert("Also, ohne Namen wird das schwierig...");
      return;
    } else {
      firebase
        .database()
        .ref("persons")
        .push({
          name: name,
          withCompanion: withCompanion,
          song,
        })
        .then(() => {
          setLoggedIn(true);
          localStorage.setItem("logged", true);
        })
        .catch((error) => {
          alert(
            "Sorry, da gab es wohl einen Fehlers. Versuche es einfach später nochmal oder schreibe mir einfach persönlich eine Nachricht."
          );
        });
    }
  };

  return (
    <>
      {!loggedIn && (
        <>
          <form onSubmit={handleSubmit}>
            <div>
              <div
                id="elmo"
                style={{
                  backgroundImage: "url(./elmo.png)",
                  maxHeight: "160px",
                }}
              ></div>
              <input
                type="text"
                placeholder="Dein Name"
                value={name}
                onChange={(e) => changeName(e.target.value)}
              />
              <input
                type="checkbox"
                id="begleitung"
                checked={withCompanion}
                onChange={(e) => setWithCompanion(e.target.checked)}
              />
            </div>
            <input
              type="text"
              placeholder="Songwunsch?"
              value={song}
              onChange={(e) => setSong(e.target.value)}
            />
            <button type="submit">Anmelden</button>
          </form>
        </>
      )}
      {loggedIn && (
        <form>
          <img
            src="./parrot-party.gif"
            alt="party"
            style={{ maxWidth: "150px" }}
          />
          <p>
            Wir sehen uns in <Countdown />!
            <br />
            <span style={{ fontSize: "12px", opacity: ".6" }}>
              (Falls es doch nicht passt, melde dich einfach bei mir)
            </span>
          </p>
        </form>
      )}
    </>
  );
};

export default Login;
