import Login from "./Login";

import "./Styles.scss";
import "./Background.scss";

function App() {
  document.addEventListener("mousemove", handleMouseMove);

  const windowWidth = window.innerWidth;

  function handleMouseMove(event) {
    document.querySelector(".shine1").style.transform = `rotate(${
      (event.clientX - windowWidth / 2) / -20
    }deg)`;
    document.querySelector(".shine2").style.transform = `rotate(${
      (event.clientX - windowWidth / 2) / -30
    }deg)`;
  }

  return (
    <>
      <div
        className="shine shine1"
        style={{ backgroundImage: "url(./shine.png)" }}
      ></div>
      <div
        className="shine shine2"
        style={{ backgroundImage: "url(./shineo.png)" }}
      ></div>
      <div>
        <div class="wave"></div>
        <div class="wave"></div>
        <div class="wave"></div>
      </div>
      <a href="./einladung.ics">
        {window.innerWidth > 600 ? <h2>Hamburger Berg 26</h2> : null}
        <h1>
          30.
          <br className="o-break" />
          August
          <br />
          20:00
          <br />
          {window.innerWidth < 600 ? <h2>Hamburger Berg 26</h2> : null}
        </h1>
      </a>
      <Login />
    </>
  );
}

export default App;
